@charset "utf-8";
//	utility
//---------------------------------------------------
// clearfix
@mixin clearfix() {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}

// scroll-bar
@mixin scroll-bar {
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: $m-gray;
		border-radius: 8px;
		box-shadow: inset 0 0 0 1px $white;
	}
}

// bg
@mixin lightgray_wave_top {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/lightgray_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: calc(100% - 2px);
	}
}
@mixin lightgray_wave_bottom {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/lightgray_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: -2px;
	}
}

@mixin white_wave_top {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/white_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: calc(100% - 2px);
	}
}
@mixin white_wave_bottom {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/white_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: -2px;
	}
}
@mixin lightblue_wave_top {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/lightblue_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: calc(100% - 2px);
	}
}
@mixin lightblue_wave_bottom {
	&::after {
		content: "";
		width: 100vw;
		height: 5.2vw;
		background: url(../img/common/lightblue_wave.svg) left bottom/cover no-repeat;
		position: absolute;
		left: 0;
		bottom: -2px;
	}
}


//	contents
//---------------------------------------------------
@mixin cont-pad($pt: 3, $pb: 4) {
	padding-top: #{$pt}rem;
	padding-bottom: #{$pb}rem;
	@include media-breakpoint-up(lg) {
		padding-top: (#{$pt} * 1.5) rem;
		padding-bottom: (#{$pb} * 1.5) rem;
	}
}
@mixin rem-pad($top: 0, $right: 0, $bottom: 0, $left: 0) {
	padding: (($top / 16) * 1rem) (($right / 16) * 1rem) (($bottom / 16) * 1rem) (($left / 16) * 1rem);
}
@mixin rem-mar($top: 0, $right: 0, $bottom: 0, $left: 0) {
	margin: (($top / 16) * 1rem) (($right / 16) * 1rem) (($bottom / 16) * 1rem) (($left / 16) * 1rem);
}
//	dafault_parts
//---------------------------------------------------
@mixin m-a {
	margin-left: auto;
	margin-right: auto;
}

@mixin dec-line {
	text-decoration: underline !important;
}
@mixin dec-none {
	text-decoration: none !important;
}

//	border-radius
//---------------------------------------------------
@mixin radius($radius: 5px) {
	border-radius: $radius;
}

@mixin radius-small {
	border-radius: clamp(8px,1vw,1em);
}

@mixin radius-medium {
	border-radius: clamp(16px,2vw,2em);
}