//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #2CABED;//メインカラー
$sub_c1:    #158EEA;//サブカラー
$sub_c2:    #B0DEF9;//サブカラー
$point_c:   #1C3AFF;//ポイントカラー
$txt_c:     #202020;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other color
$other_c1:  #003694;
$other_c2:  #5C7DB1;
$other_c3:  #0087CA;
$other_c4:  #054769;
$other_c5:  #1C2F50;
$other_c6:  #0098E0;
$other_c7:  #3BB7AA;

// backgraound
$bg_blue1:   #B6E4FF;
$bg_blue2:   #D4EFFF;
$bg_blue3:   #E9F7FF;
 
// mono tone
$white:     #fff;   //ホワイト
$o-white:   #F7F9FB;//オフホワイト
$p-gray:    #f6f6f6;   //ペールグレー
$l-gray:    #E5E9EC;   //ライトグレー
$m-gray:    #C9CED2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #232729;   //ブラックグレー
$black:     #000;   //ブラック

// color tone
$red:       #E1091F;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク

