@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1557px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1057px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1919px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1556px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1056px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1057px) and (max-width: 1556px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1557px) and (max-width: 1919px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-view {
    height: 100vw;
  }
}
@media (min-width: 1057px) {
  .main-view {
    height: 75vw;
  }
}
@media (min-width: 1557px) {
  .main-view {
    height: 1128px;
  }
}
@media (min-width: 1920px) {
  .main-view {
    height: 58.75vw;
  }
}
@media (max-width: 767px) {
  .main-view {
    height: 133.33vw;
  }
}
.main-view::before {
  content: "";
  position: absolute;
  background: url(../img/top/main_bg_add.png) center center/cover no-repeat;
  width: 100%;
}
@media (min-width: 768px) {
  .main-view::before {
    top: -15vw;
    right: calc(50% - 22vw);
    width: 100vw;
    height: 88.6vw;
  }
}
@media (min-width: 1057px) {
  .main-view::before {
    top: -5vw;
    right: calc(50% - 14vw);
    width: 85vw;
    height: 75.31vw;
  }
}
@media (min-width: 1557px) {
  .main-view::before {
    width: 1138px;
    height: 1008px;
    top: -28px;
    right: calc(50% - 74px);
  }
}
@media (min-width: 1920px) {
  .main-view::before {
    width: 59.27vw;
    height: 52.5vw;
  }
}
@media (max-width: 767px) {
  .main-view::before {
    width: 120vw;
    height: 106.32vw;
    top: -10vw;
    right: 5vw;
  }
}

.main-view .l-cont {
  position: relative;
  height: 100%;
}

.main-view__catch {
  position: absolute;
  left: 5%;
  z-index: 3;
}
@media (min-width: 768px) and (max-width: 1056px) {
  .main-view__catch {
    top: 20vw;
    width: 70%;
  }
}
@media (min-width: 1057px) {
  .main-view__catch {
    top: 40%;
    width: 45%;
    transform: translateY(-50%);
  }
}
@media (min-width: 1557px) {
  .main-view__catch {
    top: 335px;
    transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .main-view__catch {
    top: 36vw;
  }
}
.main-view__catch__img {
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) and (max-width: 1056px) {
  .main-view__catch__img {
    width: 46vw;
    max-width: 390px;
  }
}
@media (min-width: 1057px) {
  .main-view__catch__img {
    width: 30%;
    min-width: 360px;
  }
}
@media (min-width: 1557px) {
  .main-view__catch__img {
    width: 390px;
  }
}
@media (min-width: 1920px) {
  .main-view__catch__img {
    width: 20.3125vw;
  }
}
@media (max-width: 767px) {
  .main-view__catch__img {
    width: 60vw;
    margin-bottom: 1.2em;
  }
}
.main-view__catch__txt {
  color: #000;
  letter-spacing: 0.15em;
  font-weight: 700;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .main-view__catch__txt {
    font-size: calc(2.25rem + 6 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1057px) {
  .main-view__catch__txt {
    font-size: clamp(20px, 2.84vw, 37px);
  }
}
@media (min-width: 1557px) {
  .main-view__catch__txt {
    font-size: 2.31rem;
  }
}
@media (min-width: 1920px) {
  .main-view__catch__txt {
    font-size: 1.95vw;
  }
}
@media (max-width: 767px) {
  .main-view__catch__txt {
    font-size: 5.72vw;
  }
}

.main-view__img {
  position: absolute;
  z-index: 2;
}
@media (min-width: 768px) {
  .main-view__img {
    left: calc(50% - 20vw);
    top: 44vw;
    width: 80%;
  }
}
@media (min-width: 1057px) {
  .main-view__img {
    left: calc(50% - 8vw);
    top: 10vw;
    width: 66%;
  }
}
@media (min-width: 1557px) {
  .main-view__img {
    top: 120px;
    width: 1130px;
    left: calc(50% - 190px);
  }
}
@media (min-width: 1920px) {
  .main-view__img {
    width: 58.85%;
  }
}
@media (max-width: 767px) {
  .main-view__img {
    width: 98%;
    bottom: 0;
    right: -10%;
  }
}

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
/* top-news */
.top-news {
  position: relative;
  z-index: 1;
  background: rgb(0, 109, 202);
  background: linear-gradient(90deg, rgb(0, 109, 202) 0%, rgb(0, 119, 206) 25%, rgb(0, 130, 212) 50%, rgb(0, 144, 220) 75%, rgb(0, 152, 224) 100%);
  padding: clamp(3rem, 6vw, 6rem) 0 clamp(8rem, 24vw, 28rem);
}
@media (min-width: 768px) {
  .top-news {
    display: flex;
    justify-content: flex-end;
  }
}
.top-news::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 2px);
  width: 100%;
  height: 15.9375vw;
  background: url(../img/top/news_wave.png) bottom left/cover no-repeat;
  overflow: hidden;
}
.top-news__add {
  position: absolute;
  z-index: 2;
}
@media (min-width: 768px) {
  .top-news__add {
    top: 120px;
    width: 72.91%;
    right: 50%;
  }
}
@media (min-width: 1557px) {
  .top-news__add {
    right: calc(50% - 60px);
  }
}
@media (max-width: 767px) {
  .top-news__add {
    min-width: 400px;
    width: 100%;
    right: 30%;
    bottom: 20vw;
  }
}
.top-news__inner {
  position: relative;
  z-index: 3;
}
@media (min-width: 768px) {
  .top-news__inner {
    width: 100%;
    max-width: 1520px;
    padding-left: 5%;
  }
}
@media (min-width: 1057px) {
  .top-news__inner {
    width: 89vw;
  }
}
@media (min-width: 1557px) {
  .top-news__inner {
    width: 79.16%;
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .top-news__flex {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto;
    column-gap: 6;
  }
}
@media (min-width: 1057px) {
  .top-news__flex {
    grid-template-columns: 320px 1fr;
  }
}
@media (min-width: 1557px) {
  .top-news__flex {
    column-gap: 10%;
  }
}
@media (min-width: 768px) {
  .top-news__left {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (max-width: 767px) {
  .top-news__left {
    padding: 0 5%;
  }
}
@media (min-width: 768px) {
  .top-news__right {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}

.top-news__tit {
  margin-bottom: calc(1.5rem + 1em);
}
.top-news__tit em {
  font-style: normal;
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  color: #fff;
  display: inline-block;
  padding-right: 0.5em;
  font-size: calc(3rem + 40 * (100vw - 280px) / 1220);
  position: relative;
  line-height: 1;
}
@media (min-width: 768px) {
  .top-news__tit em {
    font-size: calc(3rem + 40 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1557px) {
  .top-news__tit em {
    font-size: 5.5rem;
  }
}
.top-news__tit span {
  display: block;
  color: #fff;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1220);
  font-weight: 700;
}
@media (min-width: 768px) {
  .top-news__tit span {
    font-size: calc(1rem + 8 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1057px) {
  .top-news__tit span {
    font-size: 1.5rem;
  }
}
.top-news__category {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-news__category__list {
  display: flex;
}
@media (min-width: 768px) {
  .top-news__category__list {
    flex-direction: column;
    row-gap: 0.75em;
    width: 180px;
  }
}
@media (max-width: 767px) {
  .top-news__category__list {
    flex-wrap: wrap;
    column-gap: 0.5em;
    row-gap: 0.3em;
  }
}
.top-news__category__list li .is-item {
  transition: all 0.3s ease 0s;
  background: #fff;
  padding: 0.8em 1em;
  border-radius: 36px;
  text-decoration: none !important;
  color: #202020;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.88rem;
  border: 1px solid #fff;
}
.top-news__category__list li .is-item span {
  padding-left: 1.5em;
  position: relative;
}
.top-news__category__list li .is-item span::before {
  transition: all 0.3s ease 0s;
  content: "●";
  color: #2CABED;
  position: absolute;
  left: 0.2em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6em;
}
.top-news__category__list li .is-item:hover {
  background: #1C3AFF;
  color: #fff;
}
.top-news__category__list li .is-item:hover span::before {
  color: #fff;
}

.top-news__item {
  display: flex;
  justify-content: flex-end;
}
.top-news__item:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 1557px) {
  .top-news__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.top-news__item a {
  display: block;
  background: #fff;
  color: #202020;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  padding: 1.25em 1em;
  width: 95%;
  transform-origin: right top;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}
.top-news__item a:after {
  content: "\e906";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .top-news__item a {
    padding: 1rem 1.25rem;
  }
}
@media (min-width: 1557px) {
  .top-news__item a {
    padding: 1.25em 1.5em;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
.top-news__item a::after {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  font-size: 1.25em;
  color: #202020;
}
.top-news__item a:hover {
  width: 100%;
  background: #003694;
}
.top-news__item a:hover .top-news__item__tit,
.top-news__item a:hover .top-news__item__list {
  color: #fff;
}
.top-news__item a:hover::after {
  color: #fff;
}
.top-news__item__inner {
  display: flex;
  column-gap: 5%;
  width: 90%;
}
.top-news__item__img {
  width: clamp(80px, 25%, 180px);
  flex-shrink: 0;
}
.top-news__item__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #E5E9EC;
}
.top-news__item__img__inner::before {
  content: "";
  display: block;
  padding-top: 75%;
  position: relative;
}
.top-news__item__img__inner > div,
.top-news__item__img__inner figure,
.top-news__item__img__inner a,
.top-news__item__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.top-news__item__txt {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.top-news__item__tit {
  transition: all 0.3s ease 0s;
  font-size: calc(0.9rem + 6 * (100vw - 280px) / 1220);
  font-weight: 500;
}
@media (min-width: 768px) {
  .top-news__item__tit {
    font-size: calc(0.8rem + 6 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1557px) {
  .top-news__item__tit {
    font-size: 1.25rem;
  }
}
.top-news__item__list {
  margin-bottom: 0.3em;
  font-size: calc(0.75rem + 2 * (100vw - 280px) / 1220);
  color: #2CABED;
  transition: all 0.3s ease 0s;
}
@media (min-width: 768px) {
  .top-news__item__list {
    display: flex;
    font-size: calc(0.8rem + 4 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1557px) {
  .top-news__item__list {
    font-size: 1.13rem;
  }
}
.top-news__item__date {
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
}
@media (min-width: 768px) {
  .top-news__item__date {
    padding-top: 0.1em;
    padding-right: 0.5em;
  }
}
.top-news__item__cat {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.1em;
  column-gap: 0.2em;
}

/* top-concept */
.top-concept {
  position: relative;
  background: #0098E0;
  color: #fff;
  z-index: 1;
  padding-bottom: clamp(6rem, 10vw, 10rem);
}
.top-concept::after {
  content: "";
  width: 100vw;
  height: 5.2vw;
  background: url(../img/common/lightblue_wave.svg) left bottom/cover no-repeat;
  position: absolute;
  left: 0;
  bottom: calc(100% - 2px);
}
@media (max-width: 1056px) {
  .top-concept {
    padding-top: 72vw;
  }
}
.top-concept__inner {
  position: relative;
  z-index: 4;
}
@media (min-width: 1057px) {
  .top-concept__inner {
    padding-top: clamp(5rem, 7vw, 8rem);
    width: 620px;
  }
}
.top-concept__inner .btn-wrap {
  display: flex;
  justify-content: center;
}

.top-concept__tit {
  display: flex;
  justify-content: center;
  margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
}
@media (min-width: 1057px) {
  .top-concept__tit {
    margin-bottom: 2.5rem;
  }
}
.top-concept__tit span {
  display: block;
  line-height: 1.4;
  font-weight: 500;
  font-size: calc(1rem + 27 * (100vw - 280px) / 1220);
}
.top-concept__tit span:nth-child(1) {
  letter-spacing: 0.15em;
}
@media (min-width: 1057px) {
  .top-concept__tit span:nth-child(1) {
    font-size: 2.38rem;
  }
}
.top-concept__tit span:nth-child(2) {
  letter-spacing: 0.15em;
  font-size: calc(1.34rem + 40 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .top-concept__tit span:nth-child(2) {
    font-size: 3.25rem;
  }
}
.top-concept__txt .txt p {
  font-weight: 500;
  line-height: 2;
  margin-bottom: 1.5rem;
}
.top-concept__txt .txt p:last-child {
  margin-bottom: 0;
}

.top-concept__problem {
  padding-top: clamp(1.5rem, 2vw, 2rem);
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}
.top-concept__problem dt {
  margin-bottom: 1.5rem;
  text-align: center;
  overflow: hidden;
}
.top-concept__problem dt span {
  padding: 0 1em;
  display: inline-block;
  position: relative;
  font-size: calc(1.14rem + 10 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .top-concept__problem dt span {
    font-size: 1.75rem;
  }
}
.top-concept__problem dt span::before, .top-concept__problem dt span::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
}
.top-concept__problem dt span::before {
  left: 100%;
}
.top-concept__problem dt span::after {
  right: 100%;
}
.top-concept__problem__list {
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .top-concept__problem__list {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 575px) {
  .top-concept__problem__list {
    display: flex;
    justify-content: space-around;
    row-gap: 4vw;
  }
}
.top-concept__problem__list li {
  width: 40%;
}
@media (min-width: 576px) {
  .top-concept__problem__list li {
    width: 22.58%;
  }
}
.top-concept__problem__list .is-circle {
  position: relative;
  width: 100%;
}
.top-concept__problem__list .is-circle::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-concept__problem__list .is-circle__inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-concept__problem__list .is-circle__inner span {
  line-height: 1.3;
  font-weight: 700;
  font-size: 1.05em;
  color: #202020;
  text-align: center;
}
@media (max-width: 1056px) {
  .top-concept__problem__list .is-circle__inner span {
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .top-concept__problem__list .is-circle__inner span {
    font-size: 1.28em;
  }
}

.top-concept__add01 {
  position: absolute;
  z-index: 2;
}
@media (min-width: 1057px) {
  .top-concept__add01 {
    max-width: 920px;
    width: 920px;
    top: -160px;
    left: calc(50% + 104px);
  }
}
@media (max-width: 1056px) {
  .top-concept__add01 {
    width: 72%;
    top: -9vw;
    left: -5%;
  }
}
.top-concept__add01__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.top-concept__add01__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-concept__add01__inner > div,
.top-concept__add01__inner figure,
.top-concept__add01__inner a,
.top-concept__add01__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.top-concept__add02 {
  position: absolute;
  z-index: 3;
}
@media (min-width: 1057px) {
  .top-concept__add02 {
    width: 470px;
    left: calc(50% + 30px);
    top: 600px;
  }
}
@media (max-width: 1056px) {
  .top-concept__add02 {
    width: 44%;
    top: 23vw;
    right: -2%;
  }
}
.top-concept__add02__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.top-concept__add02__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-concept__add02__inner > div,
.top-concept__add02__inner figure,
.top-concept__add02__inner a,
.top-concept__add02__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* top-product */
.top-product {
  position: relative;
  background: url(../img/top/top_product_bg.jpg) center center/cover no-repeat;
  padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 10vw, 10rem);
  overflow: hidden;
}
.top-product::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(28, 47, 80, 0.8);
}
.top-product__add {
  position: absolute;
  z-index: 2;
}
@media (min-width: 1557px) {
  .top-product__add {
    width: 880px;
    top: -84px;
    right: calc(50% + 180px);
  }
}
@media (max-width: 1556px) {
  .top-product__add {
    width: 58.66%;
    top: -4vw;
    left: -4vw;
    opacity: 0.4;
  }
}
.top-product .l-cont_xxl {
  position: relative;
  z-index: 3;
}
@media (min-width: 1057px) {
  .top-product .l-cont_xxl {
    display: flex;
    justify-content: flex-end;
  }
}

.top-product__inner {
  color: #fff;
}
@media (min-width: 1057px) {
  .top-product__inner {
    width: 950px;
  }
}
@media (min-width: 1557px) {
  .top-product__inner {
    width: 54.97%;
  }
}
.top-product__tit {
  margin-bottom: calc(1.5rem + 1em);
}
.top-product__tit em {
  font-style: normal;
  font-family: 'Gantari', sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  color: #2CABED;
  display: inline-block;
  padding-right: 0.5em;
  font-size: calc(2rem + 70 * (100vw - 280px) / 1220);
  position: relative;
  line-height: 1;
}
@media (min-width: 1557px) {
  .top-product__tit em {
    font-size: 6.25rem;
  }
}
.top-product__tit em::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 100%;
  content: "";
  width: 100vw;
  height: 2px;
  background: #2CABED;
}
.top-product__tit span {
  display: block;
  color: #fff;
  font-size: calc(1.25rem + 10 * (100vw - 280px) / 1220);
  font-weight: 700;
}
@media (min-width: 1057px) {
  .top-product__tit span {
    font-size: 1.88rem;
  }
}
.top-product__txt .txt {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-product__txt .btn-wrap {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .top-product__txt .btn-wrap {
    justify-content: flex-end;
  }
}

.top-product__list {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  counter-reset: product-num;
}
.top-product li {
  position: relative;
  padding-left: 40px;
  display: flex;
  align-items: center;
  min-height: 70px;
}
@media (min-width: 768px) {
  .top-product li {
    min-height: 120px;
    padding-left: 60px;
  }
}
@media (max-width: 767px) {
  .top-product li {
    margin-bottom: 0.5rem;
  }
}
.top-product__num {
  position: absolute;
  top: 0;
  left: 0;
}
.top-product__num__inner {
  background: #2CABED;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .top-product__num__inner {
    width: 120px;
    height: 120px;
  }
}
.top-product__num__inner span {
  font-family: 'Gantari', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.1;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .top-product__num__inner span {
    font-size: 1.13rem;
  }
}
.top-product__num__inner span::after {
  counter-increment: product-num;
  content: counter(product-num, decimal-leading-zero);
  font-family: 'Akshar', sans-serif;
  font-weight: 600;
  display: block;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .top-product__num__inner span::after {
    font-size: 3.25rem;
  }
}
@media (min-width: 768px) {
  .top-product__item {
    min-width: 56vw;
  }
}
@media (min-width: 1057px) {
  .top-product__item {
    min-width: 660px;
  }
}
@media (min-width: 1557px) {
  .top-product__item {
    min-width: 34.5vw;
  }
}
@media (max-width: 767px) {
  .top-product__item {
    width: 100%;
  }
}
.top-product__item span {
  width: 100%;
  color: #202020;
  font-weight: 600;
  display: inline-block;
  background: #fff;
  padding: 0.75em 1em 0.75em 50px;
  font-size: calc(0.84rem + 8 * (100vw - 280px) / 1220);
  line-height: 1.4;
}
@media (min-width: 768px) {
  .top-product__item span {
    padding: 1.25rem 1em 1.25rem 80px;
    min-width: 100%;
    font-size: calc(1.14rem + 8 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1057px) {
  .top-product__item span {
    padding: 1.4rem 0.5em 1.4rem 80px;
    font-size: 1.38rem;
  }
}
@media (min-width: 1557px) {
  .top-product__item span {
    font-size: calc(1.25rem + 4 * (100vw - 1500px) / 420);
  }
}
@media (min-width: 1920px) {
  .top-product__item span {
    font-size: 1.75rem;
  }
}

.top-product__list li {
  position: relative;
}
@media (min-width: 768px) {
  .top-product__list li:nth-child(2) {
    margin-top: -0.5em;
    margin-left: 70px;
  }
}
@media (min-width: 768px) {
  .top-product__list li:nth-child(3) {
    margin-top: -0.5em;
    margin-left: 140px;
  }
}
@media (min-width: 768px) {
  .top-product__list li:nth-child(4) {
    margin-top: -0.5em;
    margin-left: 210px;
  }
}

/* top-method */
.top-method {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1057px) {
  .top-method {
    height: 100vw;
    min-height: 1000px;
  }
}
@media (min-width: 1557px) {
  .top-method {
    max-height: 1580px;
  }
}
@media (max-width: 1056px) {
  .top-method {
    margin-bottom: clamp(3rem, 5vw, 5rem);
    padding-top: 66vw;
  }
}
@media (max-width: 767px) {
  .top-method {
    padding-top: 75vw;
  }
}
.top-method__inner {
  position: relative;
  z-index: 3;
}
@media (min-width: 1057px) and (max-width: 1556px) {
  .top-method__inner {
    top: 26vw;
    left: 10vw;
  }
}
@media (min-width: 1557px) {
  .top-method__inner {
    top: 390px;
  }
}
.top-method__txt {
  background: #2CABED;
  color: #fff;
}
@media (min-width: 1057px) {
  .top-method__txt {
    width: 714px;
    height: 714px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1056px) {
  .top-method__txt {
    border-radius: clamp(16px, 2vw, 2em);
    padding: 3em 8%;
  }
}
@media (max-width: 575px) {
  .top-method__txt {
    padding: 3em 5%;
  }
}
@media (min-width: 1057px) {
  .top-method__txt__inner {
    width: 400px;
    margin-top: 1rem;
  }
}
.top-method__txt .txt {
  margin-bottom: clamp(2rem, 4vw, 4rem);
}
.top-method__tit {
  margin-bottom: calc(1.5rem + 0.3em);
}
.top-method__tit em {
  font-style: normal;
  font-family: 'Gantari', sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1220);
}
@media (min-width: 1057px) {
  .top-method__tit em {
    font-size: 5.63rem;
  }
}
.top-method__tit span {
  display: block;
  font-size: calc(1.25rem + 10 * (100vw - 280px) / 1220);
  font-weight: 700;
}
@media (min-width: 1057px) {
  .top-method__tit span {
    font-size: 1.88rem;
  }
}

.top-method__add01 {
  position: absolute;
  z-index: 1;
}
@media (min-width: 1057px) and (max-width: 1556px) {
  .top-method__add01 {
    max-width: 1250px;
    width: 66%;
    top: 10vw;
    left: calc(50% + 5vw);
  }
}
@media (min-width: 1557px) {
  .top-method__add01 {
    top: 160px;
    left: calc(50% - 230px);
    width: 1250px;
  }
}
@media (max-width: 1056px) {
  .top-method__add01 {
    width: 80%;
    top: 8vw;
    left: -5%;
  }
}
.top-method__add01__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.top-method__add01__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-method__add01__inner > div,
.top-method__add01__inner figure,
.top-method__add01__inner a,
.top-method__add01__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.top-method__add02 {
  position: absolute;
}
@media (min-width: 1057px) and (max-width: 1556px) {
  .top-method__add02 {
    z-index: 2;
    max-width: 540px;
    width: 36%;
    right: calc(50% + 20vw);
    bottom: 8vw;
  }
}
@media (min-width: 1557px) {
  .top-method__add02 {
    bottom: 160px;
    right: calc(50% + 470px);
    width: 540px;
  }
}
@media (max-width: 1056px) {
  .top-method__add02 {
    z-index: 4;
    width: 40%;
    top: 40vw;
    right: -2%;
  }
}
@media (max-width: 767px) {
  .top-method__add02 {
    top: 50vw;
  }
}
.top-method__add02__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.top-method__add02__inner::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-method__add02__inner > div,
.top-method__add02__inner figure,
.top-method__add02__inner a,
.top-method__add02__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* top-cases */
.top-cases {
  padding-top: clamp(3rem, 5vw, 5rem);
  padding-bottom: clamp(3rem, 5vw, 5rem);
}
.top-cases__tit {
  text-align: center;
  margin-bottom: calc(1.5rem + 1em);
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.top-cases__tit em {
  position: relative;
  font-weight: 600;
  font-style: normal;
  display: inline-block;
  color: #2CABED;
  line-height: 1;
  font-size: calc(1.5rem + 40 * (100vw - 280px) / 1220);
  padding: 0 0.5em;
}
@media (min-width: 1557px) {
  .top-cases__tit em {
    font-size: 4.38rem;
  }
}
.top-cases__tit em::before, .top-cases__tit em::after {
  position: absolute;
  content: "";
  width: 70%;
  height: 2px;
  top: calc(50% - 1px);
  background: #2CABED;
}
.top-cases__tit em::before {
  left: 100%;
}
.top-cases__tit em::after {
  right: 100%;
}
.top-cases__tit span {
  display: block;
  margin-top: clamp(0.5rem, 2vw, 1.5rem);
  font-size: calc(1.14rem + 6 * (100vw - 280px) / 1220);
}
@media (min-width: 1557px) {
  .top-cases__tit span {
    font-size: 1.75rem;
  }
}

/* top-partner */
.top-partner {
  padding: clamp(3rem, 6vw, 6rem) 0 clamp(4rem, 8vw, 8rem);
}
.top-partner__inner {
  position: relative;
}
.top-partner__inner a {
  position: relative;
  text-decoration: none !important;
  color: #fff;
  border-radius: clamp(8px, 1vw, 1em);
  padding: 1rem 10% clamp(2rem, 3vw, 3rem);
  display: block;
  transition: all 0.3s ease 0s;
  background: url(../img/top/top_partner_bg.jpg) center center/cover no-repeat;
}
@media (min-width: 768px) {
  .top-partner__inner a {
    padding: 0 10% clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1557px) {
  .top-partner__inner a {
    min-height: 360px;
  }
}
.top-partner__inner a::before {
  border-radius: clamp(8px, 1vw, 1em);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 71, 105, 0.64);
}
.top-partner__inner a:hover .top-partner__tit__inner span {
  color: #2CABED;
}
.top-partner__inner a:hover .top-partner__tit__inner span i {
  color: #2CABED;
}

.top-partner__flex {
  position: relative;
  z-index: 4;
}
@media (min-width: 768px) {
  .top-partner__flex {
    display: flex;
    align-items: center;
    column-gap: 5%;
  }
}
.top-partner__left {
  position: relative;
}
@media (min-width: 768px) {
  .top-partner__left {
    margin-top: -3rem;
    width: 35%;
  }
}
@media (max-width: 767px) {
  .top-partner__left {
    max-width: 220px;
    top: -7vw;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .top-partner__right {
    width: 60%;
  }
}
.top-partner__tit {
  position: relative;
}
.top-partner__tit::before {
  content: "";
  display: block;
  padding-top: 100%;
  position: relative;
}
.top-partner__tit__inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
}
.top-partner__tit__inner div {
  text-align: center;
}
.top-partner__tit__inner div > * {
  display: block;
}
.top-partner__tit em {
  font-family: 'Gantari', sans-serif;
  color: #2CABED;
  letter-spacing: 0;
  font-style: normal;
  font-size: calc(2rem + 30 * (100vw - 280px) / 1220);
}
@media (min-width: 768px) {
  .top-partner__tit em {
    font-size: calc(2rem + 30 * (100vw - 768px) / 732);
  }
}
@media (min-width: 1557px) {
  .top-partner__tit em {
    font-size: 4.38rem;
  }
}
.top-partner__tit span {
  display: block;
  color: #202020;
  font-weight: 600;
  font-size: calc(1rem + 3 * (100vw - 280px) / 1220);
  transition: all 0.3s ease 0s;
}
@media (min-width: 1557px) {
  .top-partner__tit span {
    font-size: 1.38rem;
  }
}
.top-partner__tit span i {
  transition: all 0.3s ease 0s;
}
.top-partner__txt p {
  font-size: 0.94em;
}
@media (max-width: 1056px) {
  .top-partner__txt p {
    font-size: 1.06em;
  }
}
@media (max-width: 767px) {
  .top-partner__txt p {
    font-size: 1.14em;
  }
}
@media (min-width: 768px) {
  .top-partner__txt p {
    font-size: 1.11em;
  }
}
@media (min-width: 768px) and (max-width: 1056px) {
  .top-partner__txt p {
    font-size: 1.26em;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .top-partner__txt p {
    font-size: 1.35em;
  }
}