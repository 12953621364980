@charset "utf-8";
@import "base";
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		height: 100vw;
	}
	@include media-breakpoint-up(lg) {
		height: 75vw;
	}
	@include media-breakpoint-up(xl) {
		height: 1128px;
	}
	@include media-breakpoint-up(xxl) {
		height: 58.75vw;
	}
	@include media-breakpoint-down(sm) {
		height: 133.33vw;
	}
	&::before {
		content: "";
		position: absolute;
		background: url(../img/top/main_bg_add.png) center center/cover no-repeat;
		width: 100%;
		@include media-breakpoint-up(md) {
			top: -15vw;
			right: calc(50% - 22vw);
			width: 100vw;
			height: 88.6vw;
		}
		@include media-breakpoint-up(lg) {
			top: -5vw;
			right: calc(50% - 14vw);
			width: 85vw;
			height: 75.31vw;
		}
		@include media-breakpoint-up(xl) {
			width: 1138px;
			height: 1008px;
			top: -28px;
			right: calc(50% - 74px);
		}
		@include media-breakpoint-up(xxl) {
			width: 59.27vw;
			height: 52.5vw;
		}
		@include media-breakpoint-down(sm) {
			width: 120vw;
			height: 106.32vw;
			top:-10vw;
			right: 5vw;
		}
	}
}

.main-view {
	.l-cont {
		position: relative;
		height: 100%;
	}
}

.main-view__catch {
	position: absolute;
	left: 5%;
	z-index: z-index(module, part02);
	@include media-breakpoint-only(md) {
		top: 20vw;
		width: 70%;
	}
	@include media-breakpoint-up(lg) {
		top: 40%;
		width: 45%;
		transform: translateY(-50%);
	}
	@include media-breakpoint-up(xl) {
		top: 335px;
		transform: translateY(0);
	}
	@include media-breakpoint-down(sm) {
		top: 36vw;
	}
	&__img {
		margin-bottom: 1.5rem;
		@include media-breakpoint-only(md) {
			width: 46vw;
			max-width: 390px;
		}
		@include media-breakpoint-up(lg) {
			width: 30%;
			min-width: 360px;
		}
		@include media-breakpoint-up(xl) {
			width: 390px;
		}
		@include media-breakpoint-up(xxl) {
			width: 20.3125vw;
		}
		@include media-breakpoint-down(sm) {
			width: 60vw;
			margin-bottom: 1.2em;
		}
	}
	&__txt {
		color: $black;
		@include l-sp(0.15em);
		@include f-w(700);
		@include line-h(1.4);
		@include media-breakpoint-up(md) {
			@include f-s_md(2.25, 6);
		}
		@include media-breakpoint-up(lg) {
			font-size: clamp(20px,2.84vw,37px);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(37);
		}
		@include media-breakpoint-up(xxl) {
			font-size: 1.95vw;
		}
		@include media-breakpoint-down(sm) {
			font-size: 5.72vw;
		}
	}
}

.main-view__img {
	position: absolute;
	z-index: z-index(module, part03);
	@include media-breakpoint-up(md) {
		left: calc(50% - 20vw);
		top: 44vw;
		width: 80%;
	}
	@include media-breakpoint-up(lg) {
		left: calc(50% - 8vw);
		top: 10vw;
		width: 66%;
	}
	@include media-breakpoint-up(xl) {
		top: 120px;
		width: 1130px;
		left: calc(50% - 190px);
	}
	@include media-breakpoint-up(xxl) {
		width: 58.85%;
	}
	@include media-breakpoint-down(sm) {
		width: 98%;
		bottom: 0;
		right: -10%;
	}
}

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
/* top-news */
.top-news {
	position: relative;
	z-index: z-index(module, part04);
	background: rgb(0, 109, 202);
	background: linear-gradient(
		90deg,
		rgba(0, 109, 202, 1) 0%,
		rgba(0, 119, 206, 1) 25%,
		rgba(0, 130, 212, 1) 50%,
		rgba(0, 144, 220, 1) 75%,
		rgba(0, 152, 224, 1) 100%
	);
	padding: clamp(3rem, 6vw, 6rem) 0 clamp(8rem, 24vw, 28rem);
	@include media-breakpoint-up(md) {
		@include flex-end;
	}
	&::before {
		content: "";
		position: absolute;
		bottom: calc(100% - 2px);
		width: 100%;
		height: 15.9375vw;
		background: url(../img/top/news_wave.png) bottom left/cover no-repeat;
		overflow: hidden;
	}
	&__add {
		position: absolute;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			top: 120px;
			width: 72.91%;
			right: 50%;
		}
		@include media-breakpoint-up(xl) {
			right: calc(50% - 60px);
		}
		@include media-breakpoint-down(sm) {
			min-width: 400px;
			width: 100%;
			right: 30%;
			bottom: 20vw;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			width: 100%;
			max-width: 1520px;
			padding-left: map-get($contmargin, sp);
		}
		@include media-breakpoint-up(lg) {
			width: 89vw;
		}
		@include media-breakpoint-up(xl) {
			width: 79.16%;
			padding-left: map-get($contmargin, pc);
		}
	}
	&__flex {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 240px 1fr;
			grid-template-rows: auto;
			column-gap: 6;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 320px 1fr;
		}
		@include media-breakpoint-up(xl) {
			column-gap: 10%;
		}
	}
	&__left {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 map-get($contmargin, sp);
		}
	}
	&__right {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
	}
}

.top-news {
	&__tit {
		margin-bottom: calc(1.5rem + 1em);
		em {
			font-style: normal;
			@include f-family(font02);
			@include f-w(600);
			@include l-sp(0);
			color: $white;
			display: inline-block;
			padding-right: 0.5em;
			@include f-s_xs(3, 40);
			position: relative;
			@include line-h(1);
			@include media-breakpoint-up(md) {
				@include f-s_md(3, 40);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(88);
			}
		}
		span {
			display: block;
			color: $white;
			@include f-s_xs(1, 8);
			@include f-w(700);
			@include media-breakpoint-up(md) {
				@include f-s_md(1, 8);
			}
			@include media-breakpoint-up(lg) {
				@include f-size(24);
			}
		}
	}
	&__category {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		&__list {
			display: flex;
			@include media-breakpoint-up(md) {
				flex-direction: column;
				row-gap: 0.75em;
				width: 180px;
			}
			@include media-breakpoint-down(sm) {
				flex-wrap: wrap;
				column-gap: 0.5em;
				row-gap: 0.3em;
			}
			li {
				.is-item {
					@include transition;
					background: $white;
					padding: 0.8em 1em;
					border-radius: 36px;
					@include dec-none;
					color: $txt_c;
					display: flex;
					align-items: center;
					@include f-w(500);
					@include f-size(14);
					border: 1px solid $white;
					span {
						padding-left: 1.5em;
						position: relative;
						&::before {
							@include transition;
							content: "●";
							color: $main_c;
							position: absolute;
							left: 0.2em;
							top: 50%;
							transform: translateY(-50%);
							font-size: 0.6em;
						}
					}
					&:hover {
						background: $point_c;
						color: $white;
						span::before {
							color: $white;
						}
					}
				}
			}
		}
	}
}

.top-news__item {
	@include flex-end;
	&:not(:last-child) {
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 1.5rem;
		}
	}
	a {
		display: block;
		background: $white;
		color: $txt_c;
		@include dec-none;
		@include transition;
		padding: 1.25em 1em;
		width: 95%;
		transform-origin: right top;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		position: relative;
		@include icon(chevron-right, after);
		@include media-breakpoint-up(md) {
			padding: 1rem 1.25rem;
		}
		@include media-breakpoint-up(xl) {
			padding: 1.25em 1.5em;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
		}
		&::after {
			position: absolute;
			top: 50%;
			right: 3%;
			transform: translateY(-50%);
			@include transition;
			font-size: 1.25em;
			color: $txt_c;
		}
		&:hover {
			width: 100%;
			background: $other_c1;
			.top-news__item__tit,
			.top-news__item__list {
				color: $white;
			}
			&::after {
				color: $white;
			}
		}
	}
	&__inner {
		display: flex;
		column-gap: 5%;
		width: 90%;
	}
	&__img {
		width: clamp(80px, 25%, 180px);
		flex-shrink: 0;
		&__inner {
			@include aspect-img(4, 3);
			border-radius: 4px;
			overflow: hidden;
			border: 1px solid $l-gray;
		}
	}
	&__txt {
		@include flex-column;
		width: 70%;
	}
	&__tit {
		@include transition;
		@include f-s_xs(0.9, 6);
		@include f-w(500);
		@include media-breakpoint-up(md) {
			@include f-s_md(0.8, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__list {
		margin-bottom: 0.3em;
		@include f-s_xs(0.75, 2);
		color: $main_c;
		@include transition;
		@include media-breakpoint-up(md) {
			display: flex;
			@include f-s_md(0.8, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(600);
		@include media-breakpoint-up(md) {
			padding-top: 0.1em;
			padding-right: 0.5em;
		}
	}
	&__cat {
		@include flex-wrap;
		row-gap: 0.1em;
		column-gap: 0.2em;
	}
}

/* top-concept */
.top-concept {
	position: relative;
	background: $other_c6;
	color: $white;
	z-index: z-index(module, part04);
	@include lightblue_wave_top;
	padding-bottom: clamp(6rem, 10vw, 10rem);
	@include media-breakpoint-down(md) {
		padding-top: 72vw;
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(lg) {
			padding-top: clamp(5rem, 7vw, 8rem);
			width: 620px;
		}
		.btn-wrap {
			display: flex;
			justify-content: center;
		}
	}
}

.top-concept {
	&__tit {
		display: flex;
		justify-content: center;
		margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
		@include media-breakpoint-up(lg) {
			margin-bottom: 2.5rem;
		}
		span {
			display: block;
			@include line-h(1.4);
			@include f-w(500);
			@include f-s_xs(1, 27);
			&:nth-child(1) {
				@include l-sp(0.15em);
				@include media-breakpoint-up(lg) {
					@include f-size(38);
				}
			}
			&:nth-child(2) {
				@include l-sp(0.15em);
				@include f-s_xs(1.34, 40);
				@include media-breakpoint-up(lg) {
					@include f-size(52);
				}
			}
		}
	}
	&__txt {
		.txt {
			& p {
				@include f-w(500);
				@include line-h(2);
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.top-concept {
	&__problem {
		padding-top: clamp(1.5rem, 2vw, 2rem);
		margin-bottom: clamp(1.5rem, 3vw, 3rem);
		dt {
			margin-bottom: 1.5rem;
			text-align: center;
			overflow: hidden;
			span {
				padding: 0 1em;
				display: inline-block;
				position: relative;
				@include f-s_xs(1.14, 10);
				@include media-breakpoint-up(lg) {
					@include f-size(28);
				}
				&::before,
				&::after {
					position: absolute;
					top: 50%;
					content: "";
					width: 100%;
					height: 1px;
					background: $white;
				}
				&::before {
					left: 100%;
				}
				&::after {
					right: 100%;
				}
			}
		}
		&__list {
			flex-wrap: wrap;
			@include media-breakpoint-up(sm) {
				@include flex-between;
			}
			@include media-breakpoint-down(xs) {
				@include flex-around;
				row-gap: 4vw;
			}
			li {
				width: 40%;
				@include media-breakpoint-up(sm) {
					width: 22.58%;
				}
			}
			.is-circle {
				position: relative;
				width: 100%;
				&::before {
					content: "";
					display: block;
					padding-top: 100%;
					position: relative;
				}
				&__inner {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					border-radius: 50%;
					background: $white;
					display: flex;
					justify-content: center;
					align-items: center;
					span {
						@include line-h(1.3);
						@include f-w(700);
						@include f-em(18);
						color: $txt_c;
						text-align: center;
					}
				}
			}
		}
	}
}
.top-concept__add01 {
	position: absolute;
	z-index: z-index(module, part03);
	@include media-breakpoint-up(lg) {
		max-width: 920px;
		width: 920px;
		top: -160px;
		left: calc(50% + 104px);
	}
	@include media-breakpoint-down(md) {
		width: 72%;
		top: -9vw;
		left: -5%;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

.top-concept__add02 {
	position: absolute;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(lg) {
		width: 470px;
		left: calc(50% + 30px);
		top: 600px;
	}
	@include media-breakpoint-down(md) {
		width: 44%;
		top: 23vw;
		right: -2%;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

/* top-product */
.top-product {
	position: relative;
	background: url(../img/top/top_product_bg.jpg) center center/cover no-repeat;
	padding: clamp(4rem, 8vw, 8rem) 0 clamp(5rem, 10vw, 10rem);
	overflow: hidden;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 100%;
		height: 100%;
		background: rgba($other_c5, 0.8);
	}
	&__add {
		position: absolute;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(xl) {
			width: 880px;
			top: -84px;
			right: calc(50% + 180px);
		}
		@include media-breakpoint-down(lg) {
			width: 58.66%;
			top: -4vw;
			left: -4vw;
			opacity: 0.4;
		}
	}
	.l-cont_xxl {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(lg) {
			@include flex-end;
		}
	}
}

.top-product {
	&__inner {
		color: $white;
		@include media-breakpoint-up(lg) {
			width: 950px;
		}
		@include media-breakpoint-up(xl) {
			width: 54.97%;
		}
	}
	&__tit {
		margin-bottom: calc(1.5rem + 1em);
		em {
			font-style: normal;
			@include f-family(font02);
			@include f-w(600);
			@include l-sp(0);
			color: $main_c;
			display: inline-block;
			padding-right: 0.5em;
			@include f-s_xs(2, 70);
			position: relative;
			@include line-h(1);
			@include media-breakpoint-up(xl) {
				@include f-size(100);
			}
			&::after {
				position: absolute;
				top: calc(50% - 1px);
				left: 100%;
				content: "";
				width: 100vw;
				height: 2px;
				background: $main_c;
			}
		}
		span {
			display: block;
			color: $white;
			@include f-s_xs(1.25, 10);
			@include f-w(700);
			@include media-breakpoint-up(lg) {
				@include f-size(30);
			}
		}
	}
	&__txt {
		.txt {
			margin-bottom: clamp(2rem, 3vw, 3rem);
		}
		.btn-wrap {
			display: flex;
			justify-content: center;
			@include media-breakpoint-up(md) {
				justify-content: flex-end;
			}
		}
	}
}

.top-product {
	&__list {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		counter-reset: product-num;
	}
	li {
		position: relative;
		padding-left: 40px;
		display: flex;
		align-items: center;
		min-height: 70px;
		@include media-breakpoint-up(md) {
			min-height: 120px;
			padding-left: 60px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 0.5rem;
		}
	}
	&__num {
		position: absolute;
		top: 0;
		left: 0;
		&__inner {
			background: $main_c;
			border-radius: 50%;
			width: 70px;
			height: 70px;
			color: $white;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-up(md) {
				width: 120px;
				height: 120px;
			}
			span {
				@include f-family(font02);
				@include f-w(500);
				@include l-sp(0);
				@include line-h(1.1);
				@include f-size(12);
				@include media-breakpoint-up(md) {
					@include f-size(18);
				}
				&::after {
					counter-increment: product-num;
					content: counter(product-num, decimal-leading-zero);
					@include f-family(font03);
					@include f-w(600);
					display: block;
					@include f-size(32);
					@include media-breakpoint-up(md) {
						@include f-size(52);
					}
				}
			}
		}
	}
	&__item {
		@include media-breakpoint-up(md) {
			min-width: 56vw;
		}
		@include media-breakpoint-up(lg) {
			min-width: 660px;
		}
		@include media-breakpoint-up(xl) {
			min-width: 34.5vw;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		span {
			width: 100%;
			color: $txt_c;
			@include f-w(600);
			display: inline-block;
			background: $white;
			padding: 0.75em 1em 0.75em 50px;
			@include f-s_xs(0.84, 8);
			@include line-h(1.4);
			@include media-breakpoint-up(md) {
				padding: 1.25rem 1em 1.25rem 80px;
				min-width: 100%;
				@include f-s_md(1.14, 8);
			}
			@include media-breakpoint-up(lg) {
				padding: 1.4rem 0.5em 1.4rem 80px;
				@include f-size(22);
			}
			@include media-breakpoint-up(xl) {
				@include f-s_xl(1.25, 4);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(28);
			}
		}
	}
}
.top-product__list li {
	position: relative;
	@for $num from 2 through 4 {
		&:nth-child(#{$num}) {
			@include media-breakpoint-up(md) {
				margin-top: -0.5em;
				margin-left: 70px * ($num - 1);
			}
		}
	}
}

/* top-method */
.top-method {
	position: relative;
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		height: 100vw;
		min-height: 1000px;
	}
	@include media-breakpoint-up(xl) {
		max-height: 1580px;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: clamp(3rem, 5vw, 5rem);
		padding-top: 66vw;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 75vw;
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include media-breakpoint-only(lg) {
			top: 26vw;
			left: 10vw;
		}
		@include media-breakpoint-up(xl) {
			top: 390px;
		}
	}
	&__txt {
		background: $main_c;
		color: $white;
		@include media-breakpoint-up(lg) {
			width: 714px;
			height: 714px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			@include radius-medium;
			padding: 3em 8%;
		}
		@include media-breakpoint-down(xs) {
			padding: 3em 5%;
		}
		&__inner {
			@include media-breakpoint-up(lg) {
				width: 400px;
				margin-top: 1rem;
			}
		}
		.txt {
			margin-bottom: clamp(2rem, 4vw, 4rem);
		}
	}
	&__tit {
		margin-bottom: calc(1.5rem + 0.3em);
		em {
			font-style: normal;
			@include f-family(font02);
			@include f-w(700);
			@include l-sp(0);
			@include line-h(1);
			@include f-s_xs(2.5, 40);
			@include media-breakpoint-up(lg) {
				@include f-size(90);
			}
		}
		span {
			display: block;
			@include f-s_xs(1.25, 10);
			@include f-w(700);
			@include media-breakpoint-up(lg) {
				@include f-size(30);
			}
		}
	}
}

.top-method__add01 {
	position: absolute;
	z-index: z-index(module, part04);
	@include media-breakpoint-only(lg) {
		max-width: 1250px;
		width: 66%;
		top: 10vw;
		left: calc(50% + 5vw);
	}
	@include media-breakpoint-up(xl) {
		top: 160px;
		left: calc(50% - 230px);
		width: 1250px;
	}
	@include media-breakpoint-down(md) {
		width: 80%;
		top: 8vw;
		left: -5%;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

.top-method__add02 {
	position: absolute;
	@include media-breakpoint-only(lg) {
		z-index: z-index(module, part03);
		max-width: 540px;
		width: 36%;
		right: calc(50% + 20vw);
		bottom: 8vw;
	}
	@include media-breakpoint-up(xl) {
		bottom: 160px;
		right: calc(50% + 470px);
		width: 540px;
	}
	@include media-breakpoint-down(md) {
		z-index: z-index(module, part01);
		width: 40%;
		top: 40vw;
		right: -2%;
	}
	@include media-breakpoint-down(sm) {
		top: 50vw;
	}
	&__inner {
		@include aspect-img(1, 1);
		border-radius: 50%;
		overflow: hidden;
	}
}

/* top-cases */
.top-cases {
	padding-top: clamp(3rem, 5vw, 5rem);
	padding-bottom: clamp(3rem, 5vw, 5rem);
	&__tit {
		text-align: center;
		margin-bottom: calc(1.5rem + 1em);
		max-width: 90%;
		@include m-a;
		overflow: hidden;
		em {
			position: relative;
			@include f-w(600);
			font-style: normal;
			display: inline-block;
			color: $main_c;
			@include line-h(1);
			@include f-s_xs(1.5, 40);
			padding: 0 0.5em;
			@include media-breakpoint-up(xl) {
				@include f-size(70);
			}
			&::before,
			&::after {
				position: absolute;
				content: "";
				width: 70%;
				height: 2px;
				top: calc(50% - 1px);
				background: $main_c;
			}
			&::before {
				left: 100%;
			}
			&::after {
				right: 100%;
			}
		}
		span {
			display: block;
			margin-top: clamp(0.5rem, 2vw, 1.5rem);
			@include f-s_xs(1.14, 6);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
}

/* top-partner */
.top-partner {
	padding: clamp(3rem, 6vw, 6rem) 0 clamp(4rem, 8vw, 8rem);
	&__inner {
		position: relative;
		a {
			position: relative;
			@include dec-none;
			color: $white;
			@include radius-small;
			padding: 1rem 10% clamp(2rem, 3vw, 3rem);
			display: block;
			@include transition;
			background: url(../img/top/top_partner_bg.jpg) center center/cover no-repeat;
			@include media-breakpoint-up(md) {
				padding: 0 10% clamp(2rem, 3vw, 3rem);
			}
			@include media-breakpoint-up(xl) {
				min-height: 360px;
			}
			&::before {
				@include radius-small;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($other_c4, 0.64);
			}
			&:hover {
				.top-partner__tit__inner {
					span {
						color: $main_c;
						i {
							color: $main_c;
						}
					}
				}
			}
		}
	}
}
.top-partner {
	&__flex {
		position: relative;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(md) {
			display: flex;
			align-items: center;
			column-gap: 5%;
		}
	}
	&__left {
		position: relative;
		@include media-breakpoint-up(md) {
			margin-top: -3rem;
			width: 35%;
		}
		@include media-breakpoint-down(sm) {
			max-width: 220px;
			top: -7vw;
			@include m-a;
		}
	}
	&__right {
		@include media-breakpoint-up(md) {
			width: 60%;
		}
	}
	&__tit {
		position: relative;
		&::before {
			content: "";
			display: block;
			padding-top: 100%;
			position: relative;
		}
		&__inner {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			@include flex-column;
			justify-content: center;
			background: $white;
			border-radius: 50%;
			div {
				text-align: center;
				& > * {
					display: block;
				}
			}
		}
		em {
			@include f-family(font02);
			color: $main_c;
			@include l-sp(0);
			font-style: normal;
			@include f-s_xs(2, 30);
			@include media-breakpoint-up(md) {
				@include f-s_md(2, 30);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(70);
			}
		}
		span {
			display: block;
			color: $txt_c;
			@include f-w(600);
			@include f-s_xs(1, 3);
			@include transition;
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
			i {
				@include transition;
			}
		}
	}
	&__txt {
		p {
			@include f-em(16);
			@include media-breakpoint-up(md) {
				@include f-em(19);
			}
		}
	}
}
