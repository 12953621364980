//	flex
//---------------------------------------------------

@mixin flex-between {
	display:flex;
	justify-content: space-between;
}

@mixin flex-around {
	display:flex;
	justify-content: space-around;
}

@mixin flex-end {
	display:flex;
	justify-content: flex-end;
}

@mixin flex-column {
	display:flex;
	flex-direction:column;
}
@mixin flex-r-reverse {
	display:flex;
	flex-direction: row-reverse;
}
@mixin flex-c-reverse {
	display:flex;
	flex-direction:column-reverse;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap:wrap;
}

